import "core-js/modules/es.array.map.js";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, isRef as _isRef, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-60a680df"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "area-num"
};
var _hoisted_2 = {
  class: "custom-select-n"
};
import { h, ref, computed } from "vue";
import globalCountryAreaNum from "@/utils/jsonInfos/globalCountryAreaNum";
import { NAvatar } from "naive-ui";
export default {
  __name: 'index',
  props: {
    width: {
      type: String,
      default: "100%"
    },
    area_code: {
      type: [String, null],
      default: null
    }
  },
  emits: ["changeAreaCode"],
  setup: function setup(__props, _ref) {
    var emits = _ref.emit;
    var props = __props;
    var area_codes = computed(function () {
      return props.area_code && props.area_code ? props.area_code : null;
    });
    var lang = localStorage.getItem("lang");
    globalCountryAreaNum.map(function (item, index) {
      switch (lang) {
        case "zh":
          item.label = item.chinese_name;
          break;
        case "en":
          item.label = item.english_name;
          break;
        case "ja":
          item.label = item.japan_name;
          break;
        default:
          item.label = item.chinese_name;
      }
      item.value = item.phone_code;
      if (item.chinese_name === "中国") {
        console.log(index);
      }
    });
    var InternationalTelephoneOptions = globalCountryAreaNum;
    var renderSingleSelectTag = function renderSingleSelectTag(_ref2) {
      var option = _ref2.option;
      return h("div", {
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          marginRight: "10px"
        }
      }, [h(NAvatar, {
        src: option.country_img ? option.country_img : globalCountryAreaNum[42].country_img,
        round: true,
        size: 24,
        style: {
          marginRight: "12px"
        }
      }), option.label ? option.label : globalCountryAreaNum[42].label]);
    };
    var renderLabel = function renderLabel(option) {
      return h("div", {
        style: {
          display: "flex",
          alignItems: "center"
        }
      }, [h(NAvatar, {
        src: option.country_img,
        round: true,
        size: "small"
      }), h("div", {
        style: {
          marginLeft: "12px",
          padding: "4px 0"
        }
      }, [h("div", null, [option.label])])]);
    };
    var changeAreaCode = function changeAreaCode(value) {
      emits("changeAreaCode", value);
    };
    return function (_ctx, _cache) {
      var _component_n_select = _resolveComponent("n-select");
      return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", {
        class: "custom-select",
        style: _normalizeStyle({
          width: __props.width
        })
      }, [_createElementVNode("div", _hoisted_1, "+" + _toDisplayString(_unref(area_codes)), 1), _createElementVNode("div", _hoisted_2, [_createVNode(_component_n_select, {
        bordered: false,
        "render-label": renderLabel,
        "render-tag": renderSingleSelectTag,
        value: _unref(area_codes),
        "onUpdate:value": [_cache[0] || (_cache[0] = function ($event) {
          return _isRef(area_codes) ? area_codes.value = $event : null;
        }), changeAreaCode],
        filterable: true,
        options: _unref(InternationalTelephoneOptions),
        clearable: "",
        placeholder: "请选择区号"
      }, null, 8, ["value", "options"])])], 4)]);
    };
  }
};